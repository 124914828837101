

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import SkillInterface from '@/interfaces/SkillInterface';

@Component
export default class Skills extends Vue {
    @Prop({ required: true, type: Array })
    public skills!: SkillInterface[];
}
