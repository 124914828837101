






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import CourseInterface from '@/interfaces/CourseInterface';

@Component
export default class Courses extends Vue {
    @Prop({ required: true, type: Array })
    public courses!: CourseInterface[];
}
