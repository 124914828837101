







































































import { DataTableHeader } from 'vuetify';
import ResumeApi from '@/services/api/ResumeApi';
import HttpAdapter from '@/adapters/HttpAdapter';
import BaseList from '@/components/base/BaseList';
import { Component, Prop } from 'vue-property-decorator';
import ResumeDetailsDialog from '@/components/resumes/ResumeDetailsDialog.vue';

@Component({
    components: {
        ResumeDetailsDialog
    }
})
export default class ResumeList extends BaseList {
    @Prop({ required: false, type: String, default: 'resume' })
    public entity!: string;

    public apiClient = (new ResumeApi(this.entity));

    public completionList: string[] = ['0-29', '30-69', '70-100'];

    public countries: string[] = [];

    public mounted(): void {
        this.getCountries();
    }

    public getCompletedColor(completed: number): string {
        if (completed < 30) {
            return '#F03153';
        }

        if (completed >= 30 && completed < 70) {
            return '#FFA133';
        }

        if (completed >= 70 && completed <= 100) {
            return '#10b58c';
        }

        return '';
    }

    public get headers(): DataTableHeader[] {
        const headers: DataTableHeader[] = [
            { text: '№', value: 'id' },
            { text: 'Title', value: 'title' },
            { text: 'Job', value: 'job_title' },
            { text: 'User Email', value: 'email' },
            { text: 'Link', value: 'link' },
            { text: 'Created At', value: 'created_at' },
            { text: '', value: 'actions', align: 'end' }
        ];

        if (this.isResume) {
            headers.splice(headers.length - 1, 0, ...[
                { text: 'Country', value: 'country' },
                { text: 'Completion', value: 'completed' }
            ]);
        }

        return headers;
    }

    public getJobTitle(resume: { job_title: string; job: Record<string, string> | undefined }): string {
        if (resume.job) {
            return resume.job.name;
        }

        return resume.job_title;
    }

    public getLink(id: number): string | void {
        const type: string = this.entity === 'resume' ? 'r' : 'l';
        const baseId: string = btoa(String(id));

        return `https://app.resumeok.com/${type}/${baseId}`;
    }

    public get isResume(): boolean {
        return this.entity === 'resume';
    }

    public async getCountries(): Promise<void> {
        try {
            const response = await HttpAdapter.get(`${process.env.VUE_APP_BACKEND_URI}/api/admin/countries`);
            const { countries } = response.data;

            this.countries = countries;
        } catch (e) {
            console.error(e);
        }
    }
}
