





























































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ResumeInterface from '@/interfaces/ResumeInterface';
import Skills from '@/components/resumes/details/Skills.vue';
import Courses from '@/components/resumes/details/Courses.vue';
import Socials from '@/components/resumes/details/Socials.vue';
import Languages from '@/components/resumes/details/Languages.vue';
import References from '@/components/resumes/details/References.vue';
import Activities from '@/components/resumes/details/Activities.vue';
import Educations from '@/components/resumes/details/Educations.vue';
import Internships from '@/components/resumes/details/Internships.vue';
import Employments from '@/components/resumes/details/Employments.vue';

@Component({
    components: {
        Skills,
        Courses,
        Socials,
        Languages,
        References,
        Activities,
        Educations,
        Employments,
        Internships
    }
})
export default class ResumeDetailsDialog extends Vue {
    @Prop({ required: true, type: Object })
    public resume!: ResumeInterface;

    public dialog: boolean = false;

    public close(): void {
        this.dialog = false;
    }

    public getJobTitle(): string {
        return this.resume.job ? this.resume.job.name : this.resume.job_title;
    }

    public getUserImage(): string {
        return `${process.env.VUE_APP_BACKEND_URI}/storage${this.resume.user_img}`;
    }
}
