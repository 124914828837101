var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Resumes ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('lazy-search',{on:{"input":function($event){return _vm.getItems(true)}},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),(_vm.isResume)?_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{attrs:{"items":_vm.completionList,"label":"Completion Range","filled":"","dense":"","clearable":""},on:{"change":function($event){return _vm.getItems(true)}},model:{value:(_vm.filters.completion),callback:function ($$v) {_vm.$set(_vm.filters, "completion", $$v)},expression:"filters.completion"}})],1):_vm._e(),(_vm.isResume)?_c('v-col',{attrs:{"md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.countries,"clearable":"","filled":"","dense":"","label":"Country"},on:{"change":function($event){return _vm.getItems(true)}},model:{value:(_vm.filters.country),callback:function ($$v) {_vm.$set(_vm.filters, "country", $$v)},expression:"filters.country"}})],1):_vm._e()],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps},on:{"update:options":[function($event){_vm.options=$event},_vm.optionChanged]},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDateString(item.created_at))+" ")]}},{key:"item.completed",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"color":_vm.getCompletedColor(item.completed),"inline":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(_vm._s(item.completed))]},proxy:true}],null,true)})]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":_vm.getLink(item.id)}},[_vm._v("Open")])]}},{key:"item.job_title",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getJobTitle(item)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isResume)?_c('resume-details-dialog',{attrs:{"resume":item}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }