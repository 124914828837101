































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import InternshipInterface from '@/interfaces/InternshipInterface';

@Component
export default class Internships extends Vue {
    @Prop({ required: true, type: Array })
    public internships!: InternshipInterface[];
}
