





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import EducationInterface from '@/interfaces/EducationInterface';

@Component
export default class Educations extends Vue {
    @Prop({ required: true, type: Array })
    public educations!: EducationInterface[];
}
