


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import LanguageInterface from '@/interfaces/LanguageInterface';

@Component
export default class Languages extends Vue {
    @Prop({ required: true, type: Array })
    public languages!: LanguageInterface[];
}
