




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ResumeList from '@/components/resumes/ResumeList.vue';

@Component({
    components: {
        ResumeList
    }
})
export default class Resumes extends Vue {
    public entity: string = 'resume';
}
