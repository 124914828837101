





























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import EmploymentInterface from '@/interfaces/EmploymentInterface';

@Component
export default class Employments extends Vue {
    @Prop({ required: true, type: Array })
    public employments!: EmploymentInterface[];
}
