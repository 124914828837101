



























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ReferenceInterface from '@/interfaces/ReferenceInterface';

@Component
export default class References extends Vue {
    @Prop({ required: true, type: Array })
    public references!: ReferenceInterface[];
}
