

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import SocialInterface from '@/interfaces/SocialInterface';

@Component
export default class Socials extends Vue {
    @Prop({ required: true, type: Array })
    public socials!: SocialInterface[];
}
